import * as React from 'react'
import Home from '@/components/Home'
import Layout from '@/components/layout'
import Header from '@/components/Header'
import StartBack from '@/components/BtnEffect/StartBack'
import Footer from '@/components/Footer'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
import SimpleLocalize from '@/SimpleLocalize'
import PrivacyPolicePage from '@/components/PrivacyPolicy'

const PrivacyPolicy = (props: any) => {
  return (
    <SimpleLocalize {...props}>
      <Layout>
        <SEO canonical={useLocation().host} />
        <StartBack />
        <Header />
        <PrivacyPolicePage />
        <Footer />
      </Layout>
    </SimpleLocalize>
  )
}

export default PrivacyPolicy
